function BusinessHoursModal({
  show,
  handleChange,
  lang,
  businessHoursTime,
  date,
  dateRange,
  toast
}) {
  const [businessHours, setBusinessHours] = React.useState([]);
  const [tempBusinessHours, setTempBusinessHours] = React.useState([]);

  React.useEffect(() => {
    let businessTime = date.map((item) => {
      return {
        ...item,
        dayRange: sortedEvents(
          _.filter(businessHoursTime, { day_of_week: item.day })
        ),
      };
    });
    setBusinessHours(businessTime);
    setTempBusinessHours(businessTime);
  }, []);

  const changeTime = (value, time) => {
    const clonedBusinessHours = _.cloneDeep(tempBusinessHours);

    const dayIndex = clonedBusinessHours.findIndex(
      (item) => item.day === time.day
    );
    if (dayIndex !== -1) {
      const { start_time, end_time } = time;
      const dayRange = clonedBusinessHours[dayIndex].dayRange;
      const updateStartTime = (index) => {
        if (index !== -1 && dayRange[index]) {
          dayRange[index].start_time = value;
          dayRange[index].error =
            dayRange[index].errorField === "start_time" ||
            dayRange[index].errorField === "all"
              ? ""
              : dayRange[index].error;
        }
      };

      const updateEndTime = (index) => {
        if (index !== -1 && dayRange[index]) {
          dayRange[index].end_time = value;
          dayRange[index].error =
            dayRange[index].errorField === "end_time" ||
            dayRange[index].errorField === "all"
              ? ""
              : dayRange[index].error;
        }
      };

      !_.isUndefined(start_time) && updateStartTime(start_time);
      !_.isUndefined(end_time) && updateEndTime(end_time);

      setTempBusinessHours(clonedBusinessHours);
    }
  };

  const sortedEvents = (time) => {
    let timeSorted = time.sort((a, b) => {

      if (a.start_time === b.start_time) {
        return 0;
      } else if (a.start_time > b.start_time) {
        return 1;
      } else {
        return -1;
      }
    });
    return timeSorted;
  };

  function mergeAndExtendIntervals(intervals) {
    if (intervals.length < 1) {
      return intervals;
    }

    intervals = sortedEvents(intervals)

    let mergedIntervals = [intervals[0]];

    for (let i = 1; i < intervals.length; i++) {
      let currentInterval = intervals[i];
      let previousInterval = mergedIntervals[mergedIntervals.length - 1];

      if (currentInterval.start_time <= previousInterval.end_time) {
        previousInterval.end_time = previousInterval.end_time <= currentInterval.end_time 
        ? currentInterval.end_time : previousInterval.end_time;
      } else {
        mergedIntervals.push(currentInterval);
      }
    }

    mergedIntervals.filter((interval) => {
      interval !== undefined;
    });

    if (!mergedIntervals[0]) {
      return [];
    }

    return mergedIntervals;
  }

  const handleSave = () => {
    let counterInvalid = 0;

    function isValidTime(timeString) {
      const timeRegex = /^(0[0-9]|1[0-9]|2[0-4]):[0-5][0-9]$/;

      return timeRegex.test(timeString);
    }

    function checkTimeRangeValid(timeRange) {
      if (
        !isValidTime(timeRange.start_time) &&
        !isValidTime(timeRange.end_time)
      ) {
        counterInvalid += 1;
        return Object.assign(timeRange, {
          errorField: "all",
          error: lang.__business_hours_error_timerange_missing,
        });
      } else if (
        !isValidTime(timeRange.start_time) ||
        !isValidTime(timeRange.end_time) ||
        timeRange.start_time > timeRange.end_time  ||
        timeRange.start_time === timeRange.end_time
      ) {
        counterInvalid += 1;
        return Object.assign(timeRange, {
          errorField: !isValidTime(timeRange.start_time)
            ? "start_time"
            : !isValidTime(timeRange.end_time)
            ? "end_time"
            : "all",
          error: lang.__business_hours_error_timerange_invalid,
        });
      } else {
        return timeRange;
      }
    }

    let checkTime = tempBusinessHours.map((item) => ({
      ...item,
      dayRange: item.dayRange.map(checkTimeRangeValid).filter(Boolean),
    }));

    if (counterInvalid === 0) {
      let businessHoursSorted = tempBusinessHours
        .map((item) => {
          let dayRangeModified = mergeAndExtendIntervals(item.dayRange);
          return {
            text: item.text,
            day: item.day,
            dayRange: dayRangeModified,
          };
        })
        .filter((item) => item !== undefined);

      setTempBusinessHours(businessHoursSorted);
      setBusinessHours(businessHoursSorted);
      handleChange({ time: businessHoursSorted });
      toast.success(lang.__business_hours_setting_success)
    } else {
      setTempBusinessHours(checkTime);
      toast.error(lang.__business_hours_setting_fail)
    }
  };

  const handleCancel = () => {
    setTempBusinessHours(businessHours);
    handleChange();
  };

  const handleDeleteTimeRange = (day, index) => {
    const clonedBusinessHours = _.cloneDeep(tempBusinessHours);
    const dayIndex = clonedBusinessHours.findIndex((item) => item.day === day);
    if (dayIndex !== -1) {
      clonedBusinessHours[dayIndex].dayRange.splice(index, 1);
      setTempBusinessHours(clonedBusinessHours);
    }
  };

  const handleAddTimeRange = (day) => {
    const clonedBusinessHours = _.cloneDeep(tempBusinessHours);
    const dayIndex = clonedBusinessHours.findIndex((item) => item.day === day);

    if (dayIndex !== -1) {
      const newTimeRange = {
        day_of_week: day,
        start_time: lang.__start_time,
        end_time: lang.__end_time,
      };

      clonedBusinessHours[dayIndex].dayRange = _.concat(
        clonedBusinessHours[dayIndex].dayRange || [],
        newTimeRange
      );

      setTempBusinessHours(clonedBusinessHours);
    }
  };

  return (
    <div>
      <div
        className={`modal ${show ? "fade in" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div class="sp-label">{lang.__business_hours_edit}</div>
            </div>
            <div
              className="modal-body"
              style={{ padding: "0 20px", height: "70vh", overflowY: "auto" }}
            >
              {tempBusinessHours.map((item) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "1px solid #E1E7F2",
                      padding: "30px 0",
                    }}
                  >
                    <div
                      class="sp-label"
                      style={{ width: "30%", textAlign: "center" }}
                    >
                      {item.text}
                    </div>
                    <div style={{ width: "70%" }}>
                      {item.dayRange.map((time, indexTime) => {
                        return (
                          <div
                            style={{ marginBottom: "20px", display: "flex" }}
                          >
                            <div>
                              <span class="dropdown">
                                <div
                                  class="dropdown-toggle"
                                  id="dropdownMenu1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                  style={{
                                    color: "#5C6575",
                                    width: 160,
                                    display: "inline-flex",
                                    alignItems: "flex-start",
                                    border: time.error
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: 4,
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i
                                    style={{
                                      color: "#AFBACF",
                                      marginRight: 10,
                                      marginTop: 3,
                                    }}
                                    class="fa-regular fa-clock"
                                  ></i>
                                  <span>{time.start_time}</span>
                                </div>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenu1"
                                  style={{
                                    width: 160,
                                    height: 400,
                                    overflowY: "auto",
                                  }}
                                >
                                  {dateRange.map((item) => {
                                    return (
                                      <li>
                                        <a
                                          style={{
                                            paddingLeft: 36,
                                            color: "#5C6575",
                                            cursor: "pointer",
                                          }}
                                          class="dropdown-item"
                                          onClick={() =>
                                            changeTime(item, {
                                              day: time.day_of_week,
                                              start_time: indexTime,
                                            })
                                          }
                                        >
                                          {item}
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </span>
                              <span style={{ margin: "0 20px" }}>
                                {lang.__to}
                              </span>
                              <span class="dropdown">
                                <div
                                  class="dropdown-toggle"
                                  id="dropdownMenu1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                  style={{
                                    color: "#5C6575",
                                    width: 160,
                                    display: "inline-flex",
                                    alignItems: "flex-start",
                                    border: time.error
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                    borderRadius: 4,
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i
                                    style={{
                                      color: "#AFBACF",
                                      marginRight: 10,
                                      marginTop: 3,
                                    }}
                                    class="fa-regular fa-clock"
                                  ></i>
                                  {time.end_time}
                                </div>
                                {time.error && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: 12,
                                      paddingLeft: 8,
                                    }}
                                  >
                                    {time.error}
                                  </div>
                                )}
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenu1"
                                  style={{
                                    width: 160,
                                    height: 400,
                                    overflowY: "auto",
                                  }}
                                >
                                  {dateRange.map((item) => {
                                    return (
                                      <li>
                                        <a
                                          style={{
                                            paddingLeft: 36,
                                            color: "#5C6575",
                                            cursor: "pointer",
                                          }}
                                          class="dropdown-item"
                                          onClick={() =>
                                            changeTime(item, {
                                              day: time.day_of_week,
                                              end_time: indexTime,
                                            })
                                          }
                                        >
                                          {item}
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </span>
                            </div>
                            <i
                              class="icon icon-trash "
                              style={{
                                color: "#AFBACF",
                                fontSize: 19,
                                marginLeft: 10,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleDeleteTimeRange(item.day, indexTime)
                              }
                            />
                          </div>
                        );
                      })}
                      {item.dayRange.length < 3 ? (
                        <div
                          style={{ cursor: "pointer", color: "#6DA7FD" }}
                          onClick={() => handleAddTimeRange(item.day)}
                        >
                          {lang.__business_add_time_range}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="modal-footer">
              <ButtonCancel
                style={{
                  fontSize: 14,
                }}
                onClick={handleCancel}
              >
                {lang.__cancel}
              </ButtonCancel>
              <Button
                style={{
                  width: 65,
                }}
                color="primary"
                onClick={handleSave}
              >
                {lang.__save}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${show ? "fade in" : ""}`}
        style={{ display: show ? "" : "none" }}
        onClick={handleChange}
      ></div>
    </div>
  );
}
